import ko from 'knockout';
import 'knockout-mapping';

import './knockout/ko_extensions';
import * as ko_helper from './knockout/ko_helper';

import './knockout/grape.ko.formatters';
import './knockout/grape.ko.binding_handlers';

window.ko = ko; // Set Global ko object
window.ko_helper = ko_helper;
