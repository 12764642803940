
GrapeApp.prototype.init = function () {
	console.debug('Grape.init()');

	async function init() {
		await window.Grape.plugins.triggerPluginHooks('onInit');

		try
		{
			window.Grape.route('/', {
				setup: () => { },
				beforeRouteEnter: (to, from) => {
					if (to?.clean_hash === '/') return window.Grape.get_default_route();
					else return true;
				},
				beforeRouteUpdate: (to, from) => {
					if (to?.clean_hash === '/') return window.Grape.get_default_route();
					else return true;
				}
			});

			await window.Grape.plugins.triggerPluginHooks('onLoad');

			Grape.router.start();
			console.debug('Grape Router is now listening');

			await window.Grape.plugins.triggerPluginHooks('afterInit');

			window.Grape.emit('finished_loading');
		}
		catch (e)
		{
			console.error('Error during initialization', e);
			window.Grape.plugins.triggerPluginHooks('onInitError', [e]);
		}
	}

	return init();
};

