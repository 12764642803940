
import ko from 'knockout';
import moment from 'moment/min/moment.min';

ko.bindingHandlers.currency = {
	update: function (element, valueAccessor, allBindingsAccessor) {
		return ko.bindingHandlers.text.update(element, function () {
			var value = +(ko.utils.unwrapObservable(valueAccessor()) || 0);
			var symbol;

			if (ko.utils.unwrapObservable(allBindingsAccessor().symbol) === undefined)
				symbol = window.Grape.config.currency_symbol || 'R';
			else
				symbol = ko.utils.unwrapObservable(allBindingsAccessor().symbol);

			return symbol + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
		});
	}
};

ko.bindingHandlers.thousands = {
	update: function (element, valueAccessor, allBindingsAccessor) {
		return ko.bindingHandlers.text.update(element, function () {
			var value = +(ko.utils.unwrapObservable(valueAccessor()) || 0);
			var rx = /(\d+)(\d{3})/;
			if (value.toString().indexOf('-') != -1)
			{
				value = value.toString().slice(1, value.toString().length);
				return String(value).replace(/^\d+/, function (w) {
					while (rx.test(w))
						w = w.replace(rx, '$1 $2');

					return '-' + w;
				});
			}
			else
			{
				return String(value).replace(/^\d+/, function (w) {
					while (rx.test(w))
						w = w.replace(rx, '$1 $2');

					return w;
				});
			}
		});
	}
};

ko.bindingHandlers.dateString = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		// TODO: Check that element is an element and not a selector
		if (valueUnwrapped)
			element.textContent = moment(valueUnwrapped).format(window.Grape.config.date_format);
		else
			return;
	}
};

ko.bindingHandlers.dateTimeString = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

		let format = 'LLL';
		if (ko.utils.unwrapObservable(allBindingsAccessor().format) === undefined)
			format = window.Grape.config.date_time_format || 'LLL';
		else
			format = ko.utils.unwrapObservable(allBindingsAccessor().format);

		// TODO: Check that element is an element and not a selector
		if (valueUnwrapped)
			element.textContent = moment(valueUnwrapped).format(format);
		else
			return;
	}
};

ko.bindingHandlers.returnKey = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		ko.utils.registerEventHandler(element, 'keydown', function (evt) {
			if (evt.keyCode === 13)
			{
				evt.preventDefault();
				valueAccessor().call(viewModel);
			}
		});
	}
};

ko.bindingHandlers.pascalCase = {
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var value = ko.toJS(valueAccessor());
		var pascalCase;
		if (typeof value === 'string')
		{ pascalCase = value.replace('_', ' ').toLowerCase().replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }); }

		else if (typeof value === 'object')
		{
			var arr = [];
			for (var i = 0; i < value.length; i++)
				arr.push(value[i].replace('_', ' ').toLowerCase().replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }));

			pascalCase = arr.join();
		}

		// TODO: Check that element is an element and not a selector
		if (pascalCase)
			element.textContent = pascalCase;
		else
			element.textContent = valueAccessor();
	}
};

ko.bindingHandlers.bsChecked = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var value = valueAccessor();
		var newValueAccessor = function () {
			return {
				change: function () {
					value(element.value);
				}
			};
		};
		ko.bindingHandlers.event.init(element, newValueAccessor,
			allBindingsAccessor, viewModel, bindingContext);
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		// TODO: Check that element is an element and not a selector
		if (element.value == ko.unwrap(valueAccessor()))
		{
			setTimeout(function () {
				element.closest('.btn').button('toggle');
			}, 1);
		}
	}
};
