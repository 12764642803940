export function log_error(...args) {
	console.error('[ROUTER]', ...args);
}

export function log_debug(...args) {
	console.debug('[ROUTER]', ...args);
}

export function log_info(...args) {
	console.info('[ROUTER]', ...args);
}

export function log_warn(...args) {
	console.warn('[ROUTER]', ...args);
}

// Get current path hash value. Use this method since window.location.hash implementation differs by browser
export function get_current_window_hash() {
	return window.location.href.split('#', 2)[1] || '';
}

export function split_uri(uri) {
	if (uri === null || uri === undefined)
		uri = '';

	uri = uri.replace(/^\//, '').replace(/\/$/, '');
	let parts = (uri === '' ? [] : uri.split('/'));
	parts.unshift('/');

	return parts;
}

export function add_parameter(parameters, key, value) {
	parameters ??= {};

	if ((key ?? '') === '') { /* Do nothing */ }
	else if ((value ?? null) === null) { /* Do nothing */ }
	else
	{
		// SAFEGUARD: Duplicates as array of values
		let previous_value = parameters[key];
		if (previous_value !== undefined)
		{
			if (previous_value instanceof Array)
				previous_value.push(value);
			else
			{
				previous_value = [
					previous_value,
					value
				];
			}

			parameters[key] = previous_value;
		}
		else parameters[key] = value;
	}
}

export function split_params(params) {
	if (params === undefined || params === null || params === '')
		return {};

	let result = {};
	let entries = params.split('&') || [];

	entries.forEach(entry => {
		let parts = entry.split('=', 2);
		let key = unescape((parts[0] || '').trim());
		let value = unescape((parts[1] || '').trim());

		add_parameter(result, key, value);
	});

	return result;
}

/**
 * Performs a deep clone on an object without recursion safety
 * @function cloneObject
 * @param {object} obj The object you're trying to deep clone
 * @param {boolean} include_functions Whether to include or skip functions from the clone
 * @param {object} recursion_guard (Internal use only) List of objects already processed as part of the clone process
 * @param {object} recursion_guard_targets (Internal use only) List of cloned objects already processed as part of the clone process
 * @param {object} clone (Internal use only) Target clone object. Passed by reference
 * @returns {object} Returns a deep-copy clone of the input object
 */
export function cloneObject(obj, include_functions, recursion_guard, recursion_guard_targets, clone) {
	clone = clone ?? {};
	include_functions = include_functions ?? true;

	recursion_guard = recursion_guard ?? [];
	recursion_guard_targets = recursion_guard_targets ?? [];

	for (let field in obj)
	{
		const value = obj[field];

		if (!include_functions && typeof (value) === 'function') continue;
		else if (value != null && typeof (value) === 'object')
		{
			// Check if the object has already been processed
			let existing_idx = recursion_guard.indexOf(value);
			if (existing_idx >= 0)
			{
				// Return the cached clone of the property
				clone[field] = recursion_guard_targets[existing_idx];
				continue;
			}
			else
			{
				// Create a new clone of the property, cache it, then populate its fields
				let new_value = value instanceof Array ? [] : {};
				recursion_guard.push(value);
				recursion_guard_targets.push(new_value);

				clone[field] = cloneObject(value, include_functions, recursion_guard, recursion_guard_targets, new_value);
			}
		}
		else
		{
			clone[field] = value;
		}
	}

	return clone;
}

export function count_matches(full_text, search_text) {
	// String split is much faster than regex split, but uses slightly more memory
	return (full_text || '').split(search_text || '').length - 1;
}

export function build_parameter_string(parameters) {
	parameters ??= {};

	let paramstrings = [];
	for (const key in parameters)
	{
		let value = parameters[key];
		if (value instanceof Array)
		{
			value.forEach(v => {
				paramstrings.push(`${escape(key)}=${escape(v)}`);
			});
		}
		else { paramstrings.push(`${escape(key)}=${escape(value)}`); }
	}

	if (paramstrings.length === 0)
		return '';
	else
		return '?' + paramstrings.join('&');
}

export function build_route_string(node_list, include_parents, include_params) {
	if (!node_list || node_list.length === 0) return '';
	include_parents = include_parents ?? false;
	include_params = include_params ?? false;

	let path = '';
	let parent_open = false;
	if (include_parents && !node_list[0].reload_parent)
	{
		path += '[';
		parent_open = true;
	}

	let parameters = null;

	node_list.forEach((r, idx) => {
		if (idx > 1) path += '/';

		if (include_parents && parent_open && r.reload_parent)
		{
			path += ']';
			parent_open = false;
		}

		if (r.node_type === 'variable') path += ':';
		path += r.path;

		parameters ??= r.parameters;
	});

	if (include_params) path += build_parameter_string(parameters);

	return path;
}

export function get_parent_depth(uri) {
	let has_parent = uri.indexOf('[') >= 0 || uri.indexOf(']') >= 0;
	if (has_parent)
	{
		// ERROR EXAMPLE: /[parent/]child
		if (!uri.startsWith('['))
		{
			log_warn(`Parsing failed on ${uri}: [ not at beginning`);
			return null;
		}

		let opening_entries = count_matches(uri, '[');
		let closing_entries = count_matches(uri, ']');

		// ERROR EXAMPLE: [/]parent/[child
		if (opening_entries > 1)
		{
			log_warn(`Parsing failed on ${uri}: Extra [`);
			return null;
		}
		// ERROR EXAMPLE: /parent/]child
		else if (opening_entries < 1)
		{
			log_warn(`Parsing failed on ${uri}: Missing [`);
			return null;
		}
		// ERROR EXAMPLE: [/]parent/]child
		else if (closing_entries > 1)
		{
			log_warn(`Parsing failed on ${uri}: Extra ]`);
			return null;
		}
		// ERROR EXAMPLE: [/parent/child
		else if (closing_entries < 1)
		{
			log_warn(`Parsing failed on ${uri}: Missing ]`);
			return null;
		}

		let parts = uri.split(']');

		// ERROR EXAMPLE: [/parent/child]
		if (split_uri(parts[1]).length === 1)
		{
			log_warn(`Parsing failed on ${uri}: No child route in URI`);
			return null;
		}

		// ERROR EXAMPLE: [/pare]nt/child
		if (!parts[0].endsWith('/') && !parts[1].startsWith('/'))
		{
			log_warn(`Parsing failed on ${uri}: ] not after complete route`);
			return null;
		}

		return split_uri(parts[0]).length - 1;
	}
	else { return 0; }
}

export function get_node_type(route_part) {
	if (route_part === undefined || route_part === null || route_part === '')
		return 'empty';
	else if (route_part.startsWith(':'))
		return 'variable';
	else
		return 'literal';
}

export function parse_route(hash, include_params) {
	if (hash === undefined || hash === null || hash === '')
	{
		log_warn(`Parsing failed on ${hash}: Hash is empty`);
		return null;
	}

	let param_split = hash.split('?', 2);
	let clean_uri = param_split[0];

	let parent_depth = get_parent_depth(hash);
	if (parent_depth === null)
		return null;

	if (parent_depth > 0)
	{
		clean_uri = clean_uri.replace(/^\[/, '').replace(/\]/, '');
	}

	let parts = split_uri(clean_uri);

	let route_nodes = [];
	let last_node;
	for (let i = 0; i < parts.length; i++)
	{
		last_node = {
			path: parts[i].replace(/^\:/, ''),
			node_type: get_node_type(parts[i]),
			reload_parent: i >= parent_depth,
			route_depth: i,
			hash: parts.slice(0, i + 1).join('/').replace(/^\/\//, '/')
		};
		route_nodes.push(last_node);
	}

	last_node.parent_depth = parent_depth;

	if (include_params)
	{
		let params = split_params(param_split[1]);
		if (params)
			last_node.parameters = params;
	}

	return route_nodes;
}

export async function default_page_setup(route, bindings, params, options) {
	return new Promise((resolve, reject) => {
		let container = document.getElementById(route?.default_container_id);
		if (container)
		{
			window.Grape.pages.load_page(route?.page, container, bindings, params, options, (err, instance) => {
				if (err)
				{
					console.error(err);
					reject(err);
				}
				else { resolve(instance); }
			});
		}
		else
		{
			log_error('Container not found:', route?.route_hash, ' - ', route?.default_container_id);
			resolve(null);
		}
	});
}

export async function default_page_load(route, bindings, params, options) {
	return new Promise(async (resolve, reject) => {
		let container = document.getElementById(route?.default_container_id);
		if (container)
		{
			let obj = Grape.pages.find_page_instance_by_element(container);
			const page = obj?.page;
			const instance = obj?.instance;

			if (page && instance?.updateData)
			{
				await instance.updateData(instance, bindings, params, options);
			}
		}

		resolve();
	});
}

export async function default_page_teardown(route, bindings, params, options) {
	return new Promise(async (resolve, reject) => {
		let container = document.getElementById(route?.default_container_id);
		if (container)
		{
			let obj = Grape.pages.find_page_instance_by_element(container);
			const page = obj?.page;
			const instance = obj?.instance;

			if (page && instance)
			{
				await page.teardown(instance, container);
				log_debug('Teardown complete:', route?.route_hash);
			}
		}

		resolve();
	});
}
