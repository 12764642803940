
/**
 * Central registry for things. Items are tracked according to a register name.
 */
class Registry
{
	#_registers = {};
	constructor()
	{
	}

	hasRegister(register_name)
	{
		return this.#_registers.hasOwnProperty(register_name);
	}

	addRegister(register_name)
	{
		if (!this.hasRegister(register_name))
			this.#_registers[register_name] = new Map();
		return this.#_registers[register_name];
	}

	/**
	 * Get register. If it doesnt exist it will be added
	 */
	getRegister(register_name)
	{
		if (!this.hasRegister(register_name))
			this.addRegister(register_name);
		return this.#_registers[register_name];
	}

	addItem(register_name, item_name, item)
	{
		return this.getRegister(register_name).set(item_name, item);
	}

	getItem(register_name, item_name)
	{
		return this.getRegister(register_name).get(item_name);
	}

	hasItem(register_name, item_name)
	{
		if (!this.hasRegister(register_name))
			return false;
		return this.getRegister(register_name).has(item_name);
	}

}

export default Registry;

