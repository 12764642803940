import ko from 'knockout';

class ComponentProvider {
	constructor(){

	}

	init(){
		ko.components.loaders.unshift({
			loadViewModel: function(name, viewModelConfig, callback) {
				callback((params, componentInfo)=>{
					const translated_template = window?.translated_pages?.[window.Grape.component.components[name]?.template_filename]?.[window.currentLanguageCode];
					if (translated_template)
						componentInfo.element.innerHTML = translated_template;

					return componentInfo.element.vm = new viewModelConfig(params, componentInfo.element, componentInfo)
				});
			}
		});
	}

	registerComponent(component){
		try {
			ko.components.register(component.name, {
				template: component.template,
				viewModel: component.viewModel
			});
		} catch (err) {
			console.error(err);
		}
	}
}

export default ComponentProvider;
