import * as RouteUtils from './RouteUtils.js';

export default class RouteMatch {
	constructor(route, bindings) {
		this.is_defined = route?.is_defined ?? false;
		this.name = route?.name ?? null;
		this.path = route?.path ?? null;
		this.route_hash = route?.hash ?? null;
		this.route_depth = route?.route_depth ?? 0;
		this.parent_depth = route?.parent_depth ?? 0;
		this.bindings = bindings ?? {};
		this.page = route?.page ?? null;
		this.default_container_id = route?.container ?? 'container';
		this.__route = route;

		// Set the hash according to bindings
		if (this.route_depth === 0) this.hash = '/';
		else
		{
			this.hash = '/' + RouteUtils.parse_route(this.route_hash).map(node => {
				let path = node?.path?.toLowerCase();
				return node.node_type === 'variable' ? escape(bindings[path]) : escape(path);
			}).slice(1).join('/');
		}
	}

	/**
	 * Searches for a route matching the given hash in the start_route's hierarchy
	 * @param {RouteDefinition} start_route The root route definition to search in
	 * @param {string} hash The hash to parse and look for
	 * @param {object} override_bindings A set of key/value pairs to replace the found bindings with if available
	 * @returns RouteMatch
	 */
	static match(start_route, hash, override_bindings) {
		// Match empty hashes to the root route
		if (hash === '') hash = '/';

		if (start_route?.route_depth > 0) return RouteMatch.log_failure('start_route is not a root node');

		let node_list = RouteUtils.parse_route(hash, true) ?? [];
		if (node_list.length === 0) return RouteMatch.log_failure('Unable to parse hash');

		let current = start_route;
		let match_list = [current];
		let params = node_list[0]?.parameters ?? {};
		let bindings = {};

		// Skip the root node for matching purposes
		for (let idx = 1; idx < node_list.length; idx++)
		{
			const node = node_list[idx];
			let search_path = node.path?.toLowerCase();

			// It is possible to have child_nodes and a a binding_child, so check if the override matches a known child
			if (override_bindings && node.node_type === 'variable')
			{
				search_path = override_bindings[search_path] ?? null;
			}

			// Find an exact match for a child node, or use the binding node if one exists as fallback
			if (current.child_routes[search_path])
			{
				current = current.child_routes[search_path];
			}
			else if (current.binding_route)
			{
				current = current.binding_route;

				if (node.path === undefined || node.path === null)
					bindings[current.path] = null;
				else
					bindings[current.path] = node.path;
			}
			else
			{
				current = null;
			}

			// No matching route option found
			if (current)
			{
				match_list.push(current);

				// Set parameters if present. parse_route places this on the last node in the node_list
				if (node.parameters)
					params = node.parameters;
			}
			else
			{
				return RouteMatch.log_failure('Unknown route:', hash);
			}
		}

		// Failover to allow a non-defined current route to match against its descendent binding_routes until it finds a viable match.
		// e.g. Allows /check to match against /check/:id/:id2/:idn, with empty bindings if there is no explicit /check route definition
		while (!current.is_defined && current.binding_route)
		{
			// TODO: Confirm this behaviour, vs cancelling navigation
			current = current.binding_route;
			match_list.push(current);
			bindings[current.path] = null;
		}

		// TODO: Confirm this behaviour, vs cancelling navigation
		// Failover to route upwards to the last defined ancestor
		/*
		if (!current.is_defined)
		{
			let trim_idx = match_list.slice().reverse().findIndex(m => m.is_defined) || -1;
			current = match_list[trim_idx];
			if (current)
				match_list = match_list.slice(0, trim_idx + 1);
		}
		*/

		// Check that route is a valid navigation target
		if (!current.is_defined) return RouteMatch.log_failure('Incomplete route:', hash);

		// Override bindings if supplied
		bindings = override_bindings ?? bindings ?? {};

		// Finch backwards compatibility HACK
		params ??= {};
		for (let key in bindings)
		{
			bindings[key] = params[key] ?? bindings[key];
		}

		return {
			hash: hash ?? null,
			clean_hash: RouteUtils.build_route_string(node_list, false, false),
			parameters: params,
			bindings: bindings,
			route: new RouteMatch(current, bindings),
			route_list: match_list.map(node => new RouteMatch(node, bindings))
		};
	}

	/**
	 * Helper method to log a consistent message and return a null value
	 * @param {message} message The failure reason
	 * @returns null
	 */
	static log_failure(...message_parts) {
		RouteUtils.log_warn('Matching failed.', ...message_parts);
		return null;
	}
}
