import * as logger from './grape.logger';


GrapeApp.prototype.shortcut_init = function () {
	Grape.log('Initializing shortcuts');

	// STRUCT: We keep a list of all the registered sequences' first keys for fast-tracking the check
	Grape.shortcut_firstkeys = [];

	// EVENT: Trigger event on every key press
	document.body.addEventListener('keydown', function (e) {
		// Control (key 17) gets handled separately
		if (e.keyCode == 17)
			return true;

		// LOGIC: Represent keys pressed in lowercase
		var key = e.key.toLowerCase();

		// ---------
		// VALIDATION LOGIC: fast-track the check if there are no stack (in other words, this is the first key pressed, and probably not a shortcut)
		if (Grape.keypress_stack == '')
		{
			// LOGIC: Does the key press exist in the array of keys logged
			if (Grape.shortcut_firstkeys.indexOf(key) < 0)
				return true;

			// Control (in case we missed it before) gets handled separately
			if (key == 'control')
				return true;

			// LOGIC: Add key pressed to the stack, this is defined in GrapeApp grape.js
			Grape.keypress_stack = key;
		}
		else
		{
			// LOGIC: Add key to stack delimited by a blank space
			Grape.keypress_stack += ' ' + key;
		}

		// ---------
		// LOGIC: Store a local representation of the Key Stack
		var keystack = Grape.keypress_stack;

		Grape.log('Keypress stack [' + keystack + ']');

		var found = null;

		// ---------
		//* * PRIMARY FUNCTIONALITY LOGIC **
		// LOOP: keys pressed and compare to shortcut keys assigned
		for (var i = 0; i < Grape.shortcut_registry.length; i++)
		{
			// LOGIC: Get shortcut key assigned
			var v = Grape.shortcut_registry[i];

			// If the shortcut (v) needs the "control" key, but it's not pressed for this event, there's no need to compare the keystack
			if (v.control === true && e.ctrlKey !== true)
				continue;

			//* * PRIMARY COMPARISON **
			// LOGIC: Compare against current keys pressed
			if (v.shortcut == keystack)
			{
				// LOGIC: Flag / Checkpoint to indicate further processing
				found = v;
				// LOGIC: End Loop
				i = Grape.shortcut_registry.length + 1; // break
			}
		}

		// LOGIC: Force trigger custom event assigned to shortcut key
		if (found != null)
		{
			//* * PRIMARY FUNCTION **

			Grape.keypress_stack = '';

			// FUNCTION: Call shortcut handler function
			var ret = found.func(e);
			if (typeof ret === 'undefined')
				return false; // If the handler function didn't return anything, do not bubble further
			else
				return ret;
		}
	});

	// EVENT: Clean keypress buffer / stack
	document.body.addEventListener('keyup', function (e) {
		Grape.keypress_stack = '';
	});
};

// ------------------
/**
 * RESULT: Register a new shortcut
 * PARAMETERS:
 * - ctx:
			- An object or string to identify the page this shortcut applies on.
			- If an object is provided, it must have a name field.
			- Use "global" for global shortcuts.
 * - shortcut: ( note: control and not ctrl is used )
			- Space separated sequence of keys, for example "control s", "control f", "alt s"
 * - func:
			- Callback function. The event argument is passed as the only argument
 */
GrapeApp.prototype.shortcut = function (ctx, shortcut, func) {
	var page_name;

	// ---------
	// LOGIC: If a String or Object was passed in ctx
	if (typeof ctx === 'string')
		page_name = ctx;
	else if (ctx.name)
		page_name = ctx.name;

	// ---------
	// LOGIC: If the above value was not found throw an error
	if (page_name == null || page_name == '')
	{
		logger.warn('Failed to register shortcut ' + shortcut + '. Missing name field in object');
		return;
	}

	var shortcut_obj = {
		ctx: ctx,		// STRUCT or STRING ( Context Object or Context String ) e.g global
		control: false,		// Bool - Control key needs to be pressed
		page_name: page_name,	// STRING: Context Name or Context
		shortcut: null,		// key combination for shortcut
		func: func		// PROCESSING: To run after shortcut key performed
	};

	// ---------
	// Add the first part of the sequence to the fast-track list
	var ar = shortcut.split(' ');

	var shortcut_str_ar = [];
	var firstkey = null;
	ar.forEach(function (v) {
		// Control key should not be in the firstkey list
		if (v == 'control')

		{ shortcut_obj.control = true; }

		else if (v && v != '')
		{
			if (!firstkey)
				firstkey = v;
			shortcut_str_ar.push(v);
		}
	});

	// Space separated string of keys (excluding control)
	shortcut_obj.shortcut = shortcut_str_ar.join(' ');

	if (!Grape.shortcut_firstkeys)
		Grape.shortcut_firstkeys = [];

	// We cache the first keys (excluding control)
	if (Grape.shortcut_firstkeys.indexOf(firstkey) < 0)
		Grape.shortcut_firstkeys.push(firstkey);

	// ---------
	// PRIMARY DATA STRUCT
	// STRUCT: Add Shortcut Object to Array
	Grape.shortcut_registry.push(shortcut_obj);
};

// ------------------
// RESULT: call this function on the unloading of a page with shortcuts
GrapeApp.prototype.clean_shortcuts = function (ctx) {
	var page_name;

	// ---------
	// LOGIC: Retrieve name of page through ctx param passed through
	if (typeof ctx === 'string')
		page_name = ctx;
	else if (ctx.name)
		page_name = ctx.name;

	if (page_name == null || page_name == '')
		return;

	Grape.log('Clearing shortcuts for ' + page_name);

	// STRUCT:
	var removing = [];

	// ---------
	// LOGIC: Store page(s) to remove locally
	for (var i = 0; i < Grape.shortcut_registry.length; i++)
	{
		// LOGIC: Get Shortcut Object {}
		var v = Grape.shortcut_registry[i];

		// LOGIC: Locate correct page to remove
		if (v.page_name == page_name)
			removing.push(i);
	}

	// ---------
	// LOGIC: Remove shortcut from registry array
	for (var i = 0; i < removing.length; i++)
		Grape.shortcut_registry.splice(removing[i] - i, 1);

	// ---------
	// LOGIC: Clean and rebuild firstkey list
	Grape.shortcut_firstkeys = [];
	for (var i = 0; i < Grape.shortcut_registry.length; i++)
	{
		// LOGIC: Retrieve shortcut object
		var v = Grape.shortcut_registry[i];

		// LOGIC: Split shortcut key from string into an array of values
		var ar = v.shortcut.split(' ');

		// control key should not be in the firstkey list
		var firstkey;
		var j = 0;
		while (j < ar.length && (!firstkey || firstkey == 'control'))
			firstkey = ar[j++];

		// LOGIC: If shortcut key does not exist in new rebuilt array, add it
		if (Grape.shortcut_firstkeys.indexOf(firstkey) < 0)
			Grape.shortcut_firstkeys.push(firstkey);
	}
};
