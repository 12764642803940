
/**
 * Utility functions for making fetch calls. An instance of this class is available as window.Grape.fetches
 */
class Fetches {
	constructor()
	{
		this.standardOptions = {
			cache: 'no-cache'
		};
	}

	/**
	 * Performs an HTTP request and returns a promise for the data.
	 * @returns {DataPromise}
	 */
	async fetch(url, options={}) {
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return response;
	}

	async fetchBlob(url, options={}) {
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return await response.blob();
	}

	async fetchJSON(url, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Accept', 'application/json');
		options.headers = headers;
		let response = await fetch (url, Object.assign({}, this.standardOptions, options));
		return await response.json();
	}

	async getJSON(url, params={}, options={}) {
		let _params = this.params2String(params);
		let _url = new URL(url, window.location);
		if (_params.toString().length)
			_url.search = _params;
		return await this.fetchJSON(_url, options);
	}

	async postJSON(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Content-type', 'application/json');
		options.headers = headers;
		options.method = 'POST';
		if (typeof data == 'string')
			options.body = data;
		else
			options.body = JSON.stringify(data);
		return await this.fetchJSON(url, options);
	}

	async deleteJSON(url, params={}, options={}) {
		let _params = this.params2String(params);
		let _url = new URL(url, window.location);
		if (_params.toString().length)
			_url.search = _params;
		options.method = 'DELETE';
		return await this.fetchJSON(_url, options);
	}

	async putJSON(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		headers.set('Content-type', 'application/json');
		options.headers = headers;
		options.method = 'PUT';
		if (typeof data == 'string')
			options.body = data;
		else
			options.body = JSON.stringify(data);
		return await this.fetchJSON(url, options);
	}


	async post(url, data, options={}) {
		let headers = new Headers(options.headers || {});
		options.headers = headers;
		options.method = 'POST';
		options.body = data;
		return await this.fetch(url, options);
	}

	params2String(params)
	{
		let s = [];
		const add = (k, v) => {
			v = typeof v === 'function' ? v() : v;
			v = v === null ? '' : v === undefined ? '' : v;
			s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
		};

		const buildParams = (prefix, obj) => {
			let i, len, key;
			if (prefix)
			{
				if (Array.isArray(obj))
					for (i = 0, len = obj.length; i < len; i++)
						buildParams(
							prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
							obj[i]
						);
				else if (obj && (!(obj.toString instanceof Function) || obj.toString === Object.prototype.toString))
					for (key in obj)
						buildParams(`${prefix}[${key}]`, obj[key]);
				else
					add(prefix, obj);
			}
			else if (Array.isArray(obj))
			{
				for (i = 0, len = obj.length; i < len; i++)
					add(obj[i].name, obj[i].value);
			}
			else
			{
				for (key in obj)
					buildParams(key, obj[key]);
			}
			return s;
		};
		return buildParams('', params).join('&');
	}

	buildURL(url, params={}, base=window.location) {
		let _url = new URL(url, base);
		let paramstring = this.params2String(params);
		_url.search = paramstring;
		return _url;
	}
}

export default Fetches;
