
import ko from 'knockout';
export function safe_observable(value, empty_value) {
	let res = value;
	empty_value = ko.utils.unwrapObservable(empty_value);
	if (!ko.isObservable(value))

		res = ko.observable(value);

	if (empty_value !== undefined)
		if (res() === undefined || res() === null) { res(empty_value); }

	return res;
};

export function safe_observableArray(value, empty_value) {
	let res = value;
	empty_value = ko.utils.unwrapObservable(empty_value);
	if (!ko.isObservable(value))
		res = ko.observableArray(value);

	if (empty_value !== undefined)
		if (res() === undefined || res() === null) { res(empty_value); }

	return res;
};

export function register_template(template_name, filename) {
	let httpRequest = new XMLHttpRequest()
	httpRequest.onreadystatechange = function (data) {
		ko.templates[template_name] = templ;
	}
	httpRequest.open('GET', filename)
	httpRequest.send()
};
