export function logging_enabled() {
	let GrapeInstance = this;
	if (!GrapeInstance) GrapeInstance = window.Grape;

	if (!GrapeInstance) return false;
	else return (GrapeInstance.config && GrapeInstance.config.debug === true);
}

export function debug() {
	if (logging_enabled())
		console.debug('GrapeUI:', ...arguments);
}

export function log() {
	if (logging_enabled())
		console.log('GrapeUI:', ...arguments);
}

export function info() {
	if (logging_enabled())
		console.info('GrapeUI:', ...arguments);
}

export function warn() {
	console.warn('GrapeUI:', ...arguments);
}

export function error() {
	console.error('GrapeUI:', ...arguments);
}
