import ko from 'knockout';

/**
 * Swap two array items in an observableArray
 * @param {integer} index1 Index of the first element to swap
 * @param {integer} index2 Index of the second element to swap
 */
ko.observableArray.fn.swap = function (index1, index2) {
	this.valueWillMutate();

	var temp = this()[index1];
	this()[index1] = this()[index2];
	this()[index2] = temp;

	this.valueHasMutated();
};

/**
 * Moves an item in an observableArray closer to the end of the array
 * @param {integer} idx Index of the array item to move towards the end of the array
 */
ko.observableArray.fn.moveUp = function (idx) {
	if (idx + 1 < this._latestValue.length)

		this.swap(idx, idx + 1);
};

/**
 * Moves an item in an observableArray closer to the start of the array
 * @param {integer} idx Index of the array item to move towards the start of the array
 */
ko.observableArray.fn.moveDown = function (idx) {
	if (idx > 0)

		this.swap(idx, idx - 1);
};

/**
 * Swap two array items in an observable that contains an array
 * @param {integer} index1 Index of the first element to swap
 * @param {integer} index2 Index of the second element to swap
 */
ko.observable.fn.swap = function (index1, index2) {
	if (this._latestValue instanceof Array)
	{
		this.valueWillMutate();

		var temp = this()[index1];
		this()[index1] = this()[index2];
		this()[index2] = temp;

		this.valueHasMutated();
	}
};

/**
 * Moves an item in an observable wrapping an array closer to the end of the array
 * @param {integer} idx Index of the array item to move towards the end of the array
 */
ko.observable.fn.moveUp = function (idx) {
	if (this._latestValue instanceof Array)
	{
		if (idx + 1 < this._latestValue.length)

			this.swap(idx, idx + 1);
	}
};

/**
 * Moves an item in an observable wrapping an array closer to the start of the array
 * @param {integer} idx Index of the array item to move towards the start of the array
 */
ko.observable.fn.moveDown = function (idx) {
	if (this._latestValue instanceof Array)
	{
		if (idx > 0)

			this.swap(idx, idx - 1);
	}
};
