
import ko from 'knockout';

/**
 * @class GrapeDialogs
 * @description An instance of this class is usually available as window.Grape.dialog
 */
export default class GrapeDialogs {
	constructor(Grape) {
		this.Grape = Grape;
		this.dialogs = {};
		this.providers = {};
	}

	registerDialogs(dialogs){
		for (let dialog of dialogs){
			this.register(dialog);
		}
	}

	register(dialog){
		this.dialogs[dialog.name] = {
			dialog_class: dialog.dialog_class,
			template: dialog.template,
			template_filename: dialog.template_filename || null,
			provider: dialog.provider || null,
			options: dialog.options || {}
		};
	}

	/**
	 * Promisified version of show(name, bindings, display_options)
	 * @param {Object} options
	 * @param {Object} options.bootstrap_options - Extra options passed to bootstrap modal function
	 * @memberof GrapeDialogs
	 * @return {Promise<any>} Returns a promise of the dialog result after closing.  The dialog result can be passed to the dialog.close() function, or returned in the instance's onClose() function, or by changing the instance's dialog_result value
	 */
	open(name, bindings, options) {
		return new Promise((resolve, reject) => {
			let jqel = this.show(name, bindings, Object.assign({
				onClose: function (d) { resolve(d); }
			}, options));
		});
	}

	/**
	 * Create and show a dialog
	 * @param {text} name - Dialog name, must be a dialog that has been previously registered,
	 * @param {object} bindings - Data to pass on to the Dialog class.
	 * @param {object} options - Options
	 * @param {function} options.onClose - Callback when the dialog is closed.
	 * @memberof GrapeDialogs
	 */
	async show(name, bindings, options = {}) {
		if (this.dialogs[name])
		{
			// Dialog definition
			let dialog = this.dialogs[name];
			let provider = null;

			if (dialog.provider && this.providers.hasOwnProperty(dialog.provider))
			{
				provider = this.providers[dialog.provider];
			}
			else
			{
				if (!Object.entries(this.providers).length)
				{
					console.error('GrapeUI: No dialog providers installed');
					return null;
				}
				else
				{
					console.warn('Provider "%s" not found. Using first available', dialog.provider);
					provider = Object.entries(this.providers)[0][1];
				}
			}

			let display_options = Object.assign({}, options);
			if (dialog.hasOwnProperty('options'))
				display_options = Object.assign(dialog.options, display_options);

			// console.debug('Loading HTML into DOM for dialog', this.dialogs[name]);

			let element = document.createElement('div');
			element.id = [
				'tmpdialog',
				name,
				Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
			].join('_');

			if (dialog.file)
			{
				let data = await fetch(dialog.file);
				element.innerHTML = data;
			}
			else if (
				window.translated_pages &&
				window.currentLanguageCode &&
				dialog.template_filename &&
				window.translated_pages[dialog.template_filename] &&
				window.translated_pages[dialog.template_filename][window.currentLanguageCode]
			)
			{
				element.innerHTML = window.translated_pages[dialog.template_filename][window.currentLanguageCode];
			}
			else if (dialog.template)
			{
				element.innerHTML = dialog.template;
			}
			// console.debug('dialog element innerHTML:', element.innerHTML);

			await provider.show({ element, dialog }, bindings, display_options);
			window.Grape.emit('dialog_shown', name, dialog, element);
			return element;
		}
		else
		{
			console.error('No dialog named ' + name + ' could be found! ');
			return null;
		}
	}
}
