
const debug = console.debug;

/**
 * @class GrapePlugins
 */
export default class GrapePlugins {

	constructor(Grape) {
		this.Grape = Grape;
		this.plugins = [];

		this.logger = Grape.logger;
	}

	registerPlugins(plugins){
		for (let plugin of plugins){
			this.register(plugin);
		};
	}

	/**
	 * Registers a plugin
	 * @name registerPlugin
	 * @param {GrapePlugin} object -
	 * @memberof GrapeProcess
	 */
	register(plugin_class, options = {}) {
		try
		{
			let instance = new plugin_class(this.Grape, options);
			this.plugins.push({ plugin_class: plugin_class, instance: instance });
			this.Grape.logger.info({ message: 'Plugin loaded [' + (instance.name || '') + '] from [' + (plugin_class.filename || '') + ']', instance: instance });
		} catch (e)
		{
			this.Grape.logger.error({ message: 'Error while loading plugin:' + e.message, error: e });
		};
	};


	/**
	 * Returns a promise that will resolve after all plugin hooks has been resolved. The plugin hooks are created sequentially
	 */
	triggerPluginHooks(hook_name, params) {
		debug('triggerPluginHooks(' + hook_name + ', ...)');

		let promise_creators = [];

		this.plugins.forEach(function (plugin) {
			if (plugin.instance[hook_name] && typeof plugin.instance[hook_name] == 'function')
			{
				let func = plugin.instance[hook_name];
				promise_creators.push([func, plugin.instance, params]);
			}
		});

		const self = this;
		return new Promise(function (resolve, reject) {
			function trigger(list, results = []) {
				function _next(res) {
					results.push(res);
					if (list.length > 0)
						trigger(list, results);
					else
					{
						debug('triggerPluginHooks(' + hook_name + ') is done. Results: [', results, ']');
						resolve(results);
					}
				}

				let l = list.shift();
				let p = null;
				try
				{
					p = l[0].apply(l[1], l[2]);
				} catch (err)
				{
					console.error('Error in plugin hook:', err);
					return _next({ status: 'rejected', reason: err });
				}

				if (p && typeof p.then == 'function')
				{
					p.then(function (res) {
						_next({ status: 'fulfilled', value: res });
					}).catch(function (err) {
						console.error('Error in plugin hook:', err);
						_next({ status: 'rejected', reason: err });
					});
				}
				else // hook did not return a promise
				{
					_next({ status: 'fulfilled', value: p });
				}
			}

			if (promise_creators.length > 0)
				trigger(promise_creators);
			else
			{
				resolve([]);
			}
		});
	};
}
