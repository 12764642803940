/**
1. EXAMPLE
-----------
- HTML: <button data-autobind="btn1">Click me!</button>
- JS: 	page_instance.btn1_click ( if it exists is bound to )

2. OVERVIEW
-----------
- Automate event handling through the use of data-autobind attribute and utilising
	conventions for naming events suchs as 'displayElementName_click'.
- Underscore click '_click' represents the event to be called which is defined
	in your JavaScript Page Class / Function

3. PARAMETERS
-----------
- Element ID ( e.g container )
- Page Class ( Reference to JS Page Class / Function )

4. OVERVIEW
-----------
- To provide an easier way of attaching event handlers to DOM elements

5. PROCESS
-----------
- Find elements with attribute data-autobind=""
- Match with functions in page_instance that have _click for example to invoke that event


**/

/**
 * Auto-bind events on elements to handlers based on name. All elements with a data-autobind attribute will be selected. The handler name is composed using the value of this attribute plus an event name (autobind_event), for example if element has a child element with data-autobind value of "button1", and page_instance has a function called button1_click, the click event will be bound to the function.
 * @memberof GrapeApp
 * @function
 * @name autobind
 * @param {Element} element -
 * @param {Object} page_instance -
 */
export default function autobind(element, page_instance) {

	console.debug('Autobinding elements in', element, 'to', page_instance);

	// names of all properties in the page instance object
	let keys = Object.getOwnPropertyNames(Object.getPrototypeOf(page_instance))
		.concat(Object.getOwnPropertyNames(page_instance));

	let list = element.querySelectorAll('*[data-autobind]');
	for (let el of list)
	{
		console.debug(`Autobind: binding element ${el}`);
		let name_check = `${el.dataset.autobind}_`;
		for (let key of keys)
		{
			if (key.startsWith(name_check) && page_instance[key] instanceof Function)
			{
				let event_name = key.substring(name_check.length);
				console.debug(`binding event ${event_name} to ${page_instance[key]}`);
				el.addEventListener(event_name, (...p) => {
					page_instance[key].call(page_instance, ...p);
				});
			}
		}
	}
}

