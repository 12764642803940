/**
 * @class GrapeComponents
 * @description An instance of this class is usually available as window.Grape.component
 */
export default class GrapeComponents {
	constructor(Grape) {
		this.Grape = Grape;
		this.components = {};
		this.providers = {};
	}

	registerProvider(name, provider){
		provider.init();
		this.providers[name] = provider;
	}

	registerComponents(components){
		for (let component of components){
			this.register(component);
		}
	}

	register(component){
		this.components[component.name] = component;
		component.provider ??= component.module_type; // Backwards compatibility
		let provider = null;
		if (component.provider && this.providers.hasOwnProperty(component.provider)) {
				provider = this.providers[component.provider];
		} else {
			if (!Object.entries(this.providers).length)
			{
				console.error('GrapeUI: No component providers installed');
				return null;
			}
			else
			{
				console.warn('Provider "%s" not found. Using first available', component.provider);
				provider = Object.entries(this.providers)[0][1];
			}
		}

		provider.registerComponent(component);
	}
}
