
/**
 * @class GrapeCache
 */
export default class GrapeCache {
	constructor(Grape) {
		this.Grape = Grape;
		this.cache_items = {};
	}

	registerCaches(caches){
		for (let cache of caches){
			this.register(cache.name, cache.options);
		};
	}

	/**
	 * Refresh function callback
	 * @callback GrapeCache~RefreshFunctionCallback
	 * @param {Object} data - Value of the cache entry
	 */

	/**
	 * Function for refreshing a cache
	 * @callback GrapeCache~RefreshFunction
	 * @param {GrapeCache~RefreshFunctionCallback} cb - Callback to be called when the function is finished.
	 */

	/**
	 * Registers a new cache entry
	 * @memberof GrapeCache
	 * @param {String} name - Cache entry name
	 * @param {Object} opts - Options
	 * @param {Object} opts.data - Initial value (optional)
	 * @param {String} opts.table - If this table and schema is set, {@link GrapeTables~select} list query will be automatically used to get the data (optional)
	 * @param {String} opts.schema - Schema name (optional)
	 * @param {GrapeTables~FilterCriteria[]} opts.filter - Array with filter criteria (optional)
	 * @param {GrapeCache~RefreshFunction} opts.refresh - Function that will be called when a refresh is needed. A callback is provided as only argument. The callback accepts two arguments: error and data. If error is null, the cache will be set to data,
	 * }
	 */
	register(name, opts) {
		let o = Object.assign({
			last_update: 0,
			lifetime: 0, // Lifetime validity of cache values (after this many seoncds the cache will refresh). No value means it doesnt expire
			refresh: null, // Function
			schema: null,
			table: null,
			data: [],
			updating: false,
			callbacks_pending: [],
			filter: []
		}, opts);

		if (!o.table && o.tablename)
		{
			o.table = o.tablename;
			delete o.tablename;
		}

		if (!o.refresh && !o.table && !o.schema)
		{
			logger.error('You need to set either a refresh function, or a tablename and schema when registering a cache');
			return;
		}

		if (o.refresh && (o.table || o.tablename || o.schema))
		{
			logger.error('You cannot set both a refresh function, and a table and schema when registering a cache');
			return;
		}

		this.cache_items[name] = o;
	}

	/**
	 * @memberof GrapeCache
	 */
	invalidate(name) {
		let c = this.cache_items[name] || null;
		if (!c)
		{
			this.Grape.logger.log_error('Could not find cache with name ' + name);
			cb(new Error('Could not find cache with name ' + name));
		}

		c.invalidated = true;
		c.data = null;
	}

	/**
	 * @memberof GrapeCache
	 */
	invalidate_all() {
		if (this.cache_items && this.cache_items.length)
		{
			this.cache_items.forEach(function(c) {
				c.invalidated = true;
				c.data = null;
			});
		}
	}

	/**
	 * Check if a cache exists
	 * @memberof GrapeCache
	 * @param {String} name - Name of cache to check
	 */
	exists(name) {
		return this.cache_items[name] !== undefined;
	}

	/**
	 * @memberof GrapeCache
	 * @param {String} name - Name of cache to refresh
	 * @param {GrapeCache~RefreshFunctionCallback} cb - Callback function
	 */
	async refresh(name) {
		let c = this.cache_items[name] || null;
		if (!c)
		{
			console.error('Could not find cache with name ' + name);
			throw new Error('Could not find cache with name ' + name);
		}

		if (c.refresh)
		{
			let p = new Promise((resolve, reject) => {
				let mp = c.refresh((err, result) => {
					if (err)
						return reject(err);
					return resolve(result);
				});
				if (mp && mp.then instanceof Function)
					mp.then(resolve).catch(reject);
			});

			let data = await p;
			c.data = data;
			c.invalidated = false;
		}
		else
		{
			let data = await this.Grape.tables.select({
				table: c.table,
				schema: c.schema,
				filter: c.filter,
				limit: c.limit || 500
			});
			c.data = data.records;
			c.invalidated = false;
		}
		return c.data;
	}

	/**
	 * Promisified version of fetch(name, cb);
	 */
	get(name) {
		return this.fetch(name);
	}

	/**
	 * Get a cached value based on name
	 * @memberof GrapeCache
	 * @param {String} name - Name of cache to get
	 * @param {GrapeCache~RefreshFunctionCallback} cb - Callback function
	 */
	fetch(name, cb=null) {
		let p = new Promise(async (resolve, reject) => {

			let c = this.cache_items[name] || null;
			if (!c)
				return reject(new Error('Could not find cache with name ' + name));

			if (c.updating)
			{
				console.debug('Cache[' + name + '] is already busy updating');
				c.callbacks_pending.push((data) => {
					resolve(data);
				});
				return true;
			}

			if (
				c.lifetime	// lifetime is set
				&& c.last_update > 0 // has been updated
				&& (parseInt(new Date().getTime() / 1000) - c.last_update) > c.lifetime // check if lifetime is smaller than seconds since last update
			)
				c.invalidated = true;

			if (c.last_update > 0 && !c.invalidated)
			{
				console.debug('Returning cached data for [' + name + ']');
				return resolve(c.data);
			}

			console.debug('Updating Cache[' + name + ']');

			c.callbacks_pending.push((data) => { resolve(data); });
			c.updating = true;
			try {
				await this.refresh(name);
			} catch (err) {
				c.updating = false;
				return reject(err);
			}
			c.updating = false;
			c.last_update = parseInt(new Date().getTime() / 1000);

			console.debug('Cache[' + name + '] updated. value=', c.data);

			while (c.callbacks_pending.length > 0)
			{
				let func = c.callbacks_pending.pop();
				func(c.data);
			}
			return true;
		});

		if (cb && cb instanceof Function)
			p.then((data) => cb(data)).catch((err) => cb(null));
		else
			return p;
	}
}
