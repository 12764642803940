
import ko from 'knockout';

ko.bindingHandlers.stopBinding = {
	init: function () {
		return {
			controlsDescendantBindings: true
		};
	}
};

ko.bindingHandlers.datepicker_value = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var value = valueAccessor();
		console.log(value);
		var newValueAccessor = function () {
			return {
				change: function () {
					value(element.value);
				}
			};
		};
		ko.bindingHandlers.event.init(element, newValueAccessor,
			allBindingsAccessor, viewModel, bindingContext);
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		let value = ko.unwrap(valueAccessor());

		// TODO: Remove jQuery and replace with vanilla, or remove the function entirely
		if ($(element).val() !== value)
		{
			if ($(element).data('datepicker'))
				$(element).datepicker('update', value);
			else
				$(element).val(value);
		}
	}
};
