

/**
 * @class GrapeTables
 */
export default class GrapeTables {
	constructor(Grape) {
		this.Grape = Grape;
	}

	/**
	 * @typedef {Object} GrapeTables~FilterCriteria
	 * @property {String} field - Field name
	 * @property {String} operator - One of '=', '>', '<', '>=', '<=', 'LIKE', 'ILIKE'
	 * @property {String} value - Filter value
	 */

	/**
	 * Get records from a whitelisted table
	 * @alias select
	 * @memberof GrapeTables
	 * @param {Object} _opts - Options
	 * @param {String} _opts.table - Table or view name
	 * @param {String} _opts.schema STRING Schema name of table or view
	 * @param {String} _opts.sortfield STRING optional Field to order by
	 * @param {String} _opts.limit NUMBER optional Record limit default 50
	 * @param {String} _opts.offset NUMBER optional Record offset default 0
	 * @param {GrapeTables~FilterCriteria[]} _opts.filter - Array of filter criteria
	 */
	async select(_opts) {
		let opts = {
			table: _opts.table || _opts.tablename,
			schema: _opts.schema
		};

		if (_opts.sortName && _opts.sortName != '')
		{
			opts.sortfield = _opts.sortName;
			opts.sortorder = _opts.sortOrder.toUpperCase();
		}
		else if (_opts.sortfield)
		{
			opts.sortfield = _opts.sortfield;
			opts.sortorder = (_opts.sortorder || 'ASC').toUpperCase();
		}

		if (_opts.searchText && _opts.searchText != '')
		{
			var operator = _opts.search_op || 'ILIKE';
			if (operator === 'ILIKE')
				_opts.searchText += '%';

			opts.filter = [{ field: _opts.search_column, operator: operator, value: _opts.searchText }];
		}
		else if (_opts.filter && _opts.filter.length)

		{ opts.filter = _opts.filter; }

		if (_opts.pageNumber && _opts.pageSize)
		{
			opts.limit = _opts.pageSize;
			opts.offset = (_opts.pageNumber - 1) * opts.limit;
		}
		else if (_opts.limit)
		{
			opts.limit = _opts.limit;
			opts.offset = _opts.offset || 0;
		}
		else
		{
			opts.limit = 20;
			opts.offset = 0;
		}

		let url = Grape.fetches.buildURL('/api/record', opts);

		let response = await fetch(url, {method: 'GET'});
		let data = await response.json();

		return data;
	}

	/**
	 * Insert a new record into a whitelisted table
	 * @memberof GrapeTables
	 * @alias insert
	 */
	async insert(opts) {
		if (!opts.table && opts.tablename)
			opts.table = opts.tablename;
		if (
			!opts.schema ||
			opts.schema === '' ||
			!opts.table ||
			opts.table === '' ||
			!opts.values ||
			opts.values === {}
		)
		{
			logger.log('GrapeUI: Invalid input for Grape.tables.insert');
			return;
		}

		let returning = opts.returning || '*';

		let response = await fetch('/api/record', {
			method: 'POST',
			body: JSON.stringify({
				schema: opts.schema,
				table: opts.table,
				values: opts.values,
				returning: returning
			}),
			headers: {'content-type': 'application/json'}
		});
		let data = await response.json();

		if (data.status && data.status === 'ERROR')
		{
			let error = new Error(`InsertRecord error: ${data.message || ''}`);
			error.reason = data;
			error.code = data.code || null;
			throw new Error(error);
		}
		
		if (opts.hasOwnProperty('done') && opts.done instanceof Function)
			await opts.done(data['return'] || null);
		return data['return'];

	}

	/**
	 * Updates a record in a whitelisted table
	 * @memberof GrapeTables
	 * @alias update
	 * @param {String} opts.schema - Schema
	 * @param {String} opts.table- Table name
	 * @param {Object[]} opts.filter - Array of filter criteria
	 * @param {String} opts.filter.field - Field to filter on
	 * @param {String} opts.filter.operator - One of '=', '>', '<', '>=', '<=', 'LIKE', 'ILIKE'
	 * @param {String} opts.filter.value - Filter value
	 */
	async update(opts) {
		if (!opts.table && opts.tablename)
			opts.table = opts.tablename;
		if (
			!opts.schema ||
			opts.schema === '' ||
			!opts.table ||
			opts.table === '' ||
			!opts.values ||
			opts.values === {} ||
			!opts.filter ||
			opts.filter === {}
		)
		{
			logger.log('GrapeUI: Invalid input for Grape.GrapeUpdateRecord');
			return;
		}

		let returning = opts.returning || '*';

		let response = await fetch(`/api/record`, {
			method: 'PATCH',
			body: JSON.stringify({
				schema: opts.schema,
				table: opts.table,
				values: opts.values,
				filter: opts.filter,
				returning: returning
			}),
			headers: {'content-type': 'application/json'}
		});
		let data = await response.json();

		if (data.status && data.status === 'ERROR')
		{
			let error = new Error(`UpdateRecord error: ${data.message || ''}`);
			error.reason = data;
			error.code = data.code || null;
			throw new Error(error);
		}
		
		if (opts.hasOwnProperty('done') && opts.done instanceof Function)
			await opts.done(data['return'] || null);
		return data['return'];
	}

	/**
	 * Deletes a record from a whitelisted table
	 * @memberof GrapeTables
	 * @alias delete
	 * @param {Object} opts - Options
	 * @param {String} opts.schema - Schema
	 * @param {String} opts.tablename - Table name
	 * @param {Object[]} opts.filter - Array with filter criteria
	 * @param {Callback} opts.done - Success callback
	 */
	async delete(opts) {
		if (!opts.table && opts.tablename)
			opts.table = opts.tablename;
		if (
			!opts.schema ||
			opts.schema === '' ||
			!opts.table ||
			opts.table === '' ||
			!opts.filter ||
			opts.filter === {}
		)
		{
			logger.log('GrapeUI: Invalid input for Grape.tables.delete');
			return;
		}

		let response = await fetch('/api/record', {
			method: 'DELETE',
			body: JSON.stringify({
				schema: opts.schema,
				table: opts.table,
				filter: opts.filter
			}),
			headers: {'content-type': 'application/json'}
		});
		let data = await response.json();

		if (data.status && data.status === 'ERROR')
		{
			let error = new Error(`DeleteRecord error: ${data.message || ''}`);
			error.reason = data;
			error.code = data.code || null;
			throw new Error(error);
		}
		
		if (opts.hasOwnProperty('done') && opts.done instanceof Function)
			await opts.done(data['return'] || null);
		return data['return'];
	}
}
