
// TODO: Accommodate frame elements

/**
 * 1. OVERVIEW
 * -----------
 * - Navigate to either 'guest' default route or a default route based on specific user_role
 *
 * 2. PROCESSING
 * ---------
 * - If previous session does not exist with 'roles'. Utilise 'guest' 'user_roles' to continue processing.
 * - Directory to navigate to is stored in GrapeApp "default_routes": {"guest": "/login"}
 * - Validate whether user has permission to navigate to that default route, if not no routing is done.
 *
 * 3. FUNCTIONS
 * ---------
 * - set_default_routes
 *		- Set a new value to default_routes which is stored in GrapeApp
 * - navigate_to_default
 * 		- Navigate to either guest default route or custom default route specified for user
 */

// ------------------
// RESULT: Overrides default route {"guest": "/login"} instantiated in grape.js
GrapeApp.prototype.set_default_routes = function (route_config) {
	window.Grape.config.default_routes = route_config;
};

GrapeApp.prototype.get_default_route = function () {
	let roles = ['guest'];
	if (Grape?.currentSession?.roles)
	{
		if (typeof Grape.currentSession.roles === 'string')
		{
			let role_str = Grape.currentSession.roles;
			if (role_str.indexOf(',') >= 0)
				roles = role_str.split(','); // Create Array from String using comma as delimeter
			else
				roles = role_str.split(' '); // Create Array from String using 'blank space' as a delimeter
		}
		else if (Array.isArray(Grape.currentSession.roles))
		{
			roles = Grape.currentSession.roles;
		}
		else
		{
			console.error('Unknown roles', Grape?.currentSession?.roles);
		}
	}

	// LOGIC: Retrieve GrapeApp JSON "default_routes": {"guest": "/login"},
	const default_routes = window.Grape?.config?.default_routes ?? {};

	let navigate = '';
	roles.forEach(function (r) {
		// LOGIC: Compare 'user role' in array (using associative name index)
		if ((default_routes[r] ?? '') !== '')
			navigate = default_routes[r]; // LOGIC: Retrieve the directory path to navigate to
	});

	return navigate;
};

// ------------------
// RESULT: Navigate to the default route, as found in config.json
GrapeApp.prototype.navigate_to_default = function () {
	Grape.log('Navigating to landing page');

	let navigate = Grape.get_default_route();
	Grape.log('Found default route as ' + navigate);

	// ----------
	// LOGIC: Navigate to default Route
	if (navigate && navigate !== '')
		Grape.router.navigate(navigate);
};
