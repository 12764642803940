import './lib/standard_external_libs';
import './lib/extensions';
import './lib/grape';
import './lib/knockout';

import package_json from '../package.json';
import ko_component_provider from './lib/knockout/grape.ko.component_provider';

document.addEventListener('GrapeAppCreated', (e) => {
	console.debug('Grape App created', e.detail);
});

const Grape = new window.GrapeApp();
window.Grape = Grape;
console.info("GRAPE: ", window.Grape);

Grape.component.registerProvider('ko', new ko_component_provider());

Grape.modules.push(package_json);

document.dispatchEvent(new CustomEvent('GrapeAppCreated', { detail: Grape }));
