

/**
 * @typedef {Object} GrapeUIConfiguration
 * @property {String} project_name - Project name
 * @property {Object} default_routes - Default routes for roles
 */

export default {
	project_name: 'Unknown',
	debug: true,
	currency_symbol: 'R',
	date_format: 'YYYY/MM/DD',
	date_time_format: 'YYYY/MM/DD HH:mm',
	navbar_element: '#menu'
};

