export function test(message) { alert(message); }

export function array_to_hex(arr) {
	let ret = '';
	for (let i = 0; i < arr.length; i++)
	{
		let hex = (arr[i] & 0xff).toString(16);
		if (arr[i] < 16)
			hex = '0' + hex;
		ret += hex;
	}
	return ret;
}

export function generate_random_string(length) {
	let ret = '';
	while (ret.length < length)
		ret = ret + Math.random().toString(36).replace(/[^a-z]+/g, '');

	if (ret.length > length)
		ret = ret.substring(0, length);

	return ret;
}

export function generate_hash_id(length) {
	let bth = function byteToHex(byte) {
		return ('0' + byte.toString(16)).slice(-2);
	};

	let arr = new Uint8Array((length || 40) / 2);
	window.crypto.getRandomValues(arr);
	return arr.map(bth).join('');
}

// Helper Function: Register pages using standard object format
export function register_pages(pages) {
	console.warn('deprecated function, use window.Grape.pages.registerPages');
	window.Grape.pages.registerPages(pages);
}

// Helper Function: Register dialogs using standard object format
export function register_dialogs(dialogs) {
	console.warn('deprecated function, use window.Grape.dialog.registerDialogs');
	window.Grape.dialog.registerDialogs(dialogs);
}

// Helper Function: Register components using standard object format
export function register_components(components) {
	console.warn('deprecated function, use window.Grape.component.registerComponents');
	window.Grape.component.registerComponents(components);
}

// Helper Function: Register caches using standard object format
export function register_caches(caches) {
	console.warn('deprecated function, use window.Grape.cache.registerCaches');
	window.Grape.cache.registerCaches(caches);
}

// Helper Function: Register plugins using standard object format
export function register_plugins(plugins) {
	console.warn('deprecated function, use window.Grape.plugins.registerPlugins');
	window.Grape.plugins.registerPlugins(plugins);
}
