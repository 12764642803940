import * as logger from '../grape/grape.logger';

document.addEventListener('ajaxSend', function (event, request, settings) {
	logger.debug('ajaxsend', settings);
});
document.addEventListener('ajaxComplete', function () { });
document.addEventListener('beforeSend', function (event, request, settings) {
	logger.debug('beforeSend');
});
document.addEventListener('ajaxError', function (event, request, settings) {
	logger.error(event);
	logger.error(request);

	logger.error('location: ' + window.location);
});
